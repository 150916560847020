<script lang="ts" setup>
// define props
const props = defineProps<{
	text: string;
	textClasses: string;
}>();
</script>

<template>
	<div class="dirt-background font-america" :class="textClasses" style="line-height: normal">
		{{ props.text }}
	</div>
</template>

<style>
.dirt-background {
	display: inline-block;
	margin: 0;
	background-image: url('../../assets/background/grunge-wall-bg.png');
	background-repeat: repeat;
	background-size: cover;
	background-position: center;
	background-color: white;
	color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
}
</style>
